(function ready(fn) {
    if (document.readyState !== 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
})(() => {
    const doc = window.document;
    const dropdowns = doc.querySelectorAll('.rheader-menu-title, .rheader-new-menu-title');
    const links = doc.querySelectorAll('.rheader-menu-title a, .rheader-new-menu-title a');
    const dropdownsItems = [].slice.call(dropdowns);
    const linksItems = [].slice.call(links);
    const menuSwitch = doc.getElementById('rheader_menu_switch');

    // show dropdown submenus on click
    dropdownsItems.forEach((item) => {
        item.addEventListener('click', () => {
            const oldItem = doc.querySelector('.rheader-menu-title--on');
            item.classList.add('rheader-menu-title--on');
            item.parentNode.classList.add('rheader-menu-drop--on');
            if (oldItem !== null) {
                oldItem.classList.remove('rheader-menu-title--on');
                oldItem.parentNode.classList.remove('rheader-menu-drop--on');
            }
        });
    });
    // don't show dropdown submenus on link click
    linksItems.forEach((item) => {
        item.addEventListener('click', (event) => {
            event.stopPropagation();
        });
    });

    // close opened submenu when main menu is closed
    if (menuSwitch !== null) {
        menuSwitch.addEventListener('change', () => {
            const oldItem = doc.querySelector('.rheader-menu-title--on');
            if (oldItem !== null) {
                oldItem.classList.remove('rheader-menu-title--on');
                oldItem.parentNode.classList.remove('rheader-menu-drop--on');
            }
        });
    }
});
